<template>
  <div class="code-dialog">
    <div class="px-10 text-center mt-10">
        <img :src="require('@/assets/images/profile/rate-draw.png')" alt="">
      <span class=" d-block font-20 black--text font-500">
        {{$t('afterYourExperience')}}</span
      >
        <!-- {{$t('afterYourExperience with this trip.. what is your rating?</span -->
      <div class="text-center d-flex justify-center my-10">
        <v-rating
          v-model="rating"
          background-color="grey"
          empty-icon="mdi-star"
          color="yellow"
          size="40"
        ></v-rating>
      </div>
      <div class="mb-10">
        <!-- <v-btn
          class="c-btn mx-1"
          color="grey"
          height="40"
          @click="$router.go()"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >No</span
          ></v-btn
        > -->
        <v-btn
          class="c-btn mx-1"
          color="primary"
          height="35"
          @click="submitHandler"
          ><span class="white--text font-300 font-20 d-inline-block "
            >{{$t('sendRate')}}</span
          ></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data:() => ({
    rating: 0
  }),
  methods:{
   async submitHandler() {
      let formData = new FormData();
      formData.append("trip_id", this.item.trip_id);
      formData.append("rate", this.rating);
      const res = await this.$axios.post("/rateTrip", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: res.data.message,
          color: "success",
        });
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },    
  }
};
</script>

<style lang="scss"></style>
